import { sharedTypes } from './Constants';

export function getUserDataRequest(options) {
	return {
		type: sharedTypes.GET_USER_DATA_REQUEST,
		payload: {
			options,
		},
	};
}

export function getUserDataSuccess(userData) {
	return {
		type: sharedTypes.GET_USER_DATA_SUCCESS,
		payload: {
			userData,
		},
	};
}

export function getUserDataError(error) {
	return {
		type: sharedTypes.GET_USER_DATA_ERROR,
		error: true,
		payload: {
			error,
		},
	};
}

export function logout(route) {
	return {
		type: sharedTypes.LOGOUT,
		payload: { route },
	};
}

export function updateUserDataRequest(data) {
	return {
		type: sharedTypes.UPDATE_USER_DATA_REQUEST,
		payload: {
			data,
		},
	};
}

export function updateUserDataSuccess() {
	return {
		type: sharedTypes.UPDATE_USER_DATA_SUCCESS,
	};
}

export function updateUserDataError(error) {
	return {
		type: sharedTypes.UPDATE_USER_DATA_ERROR,
		error: true,
		payload: {
			error,
		},
	};
}

export function clearUserData() {
	return {
		type: sharedTypes.CLEAR_USER_DATA,
	};
}

export function sendEventRequest(data) {
	return {
		type: sharedTypes.SEND_EVENT_REQUEST,
		payload: { data },
	};
}

export function sendEventSuccess(data) {
	return {
		type: sharedTypes.SEND_EVENT_SUCCESS,
		payload: { data },
	};
}

export function sendEventError() {
	return {
		type: sharedTypes.SEND_EVENT_ERROR,
	};
}

export function setTranslationsInstance(translationsInstance) {
	return {
		type: sharedTypes.SET_TRANSLATIONS_INSTANCE,
		payload: { translationsInstance },
	};
}

export function setBackgroundColor(backgroundColor) {
	return {
		type: sharedTypes.SET_BACKGROUND_COLOR,
		payload: { backgroundColor },
	};
}

export function setBackgroundPattern(backgroundPattern) {
	return {
		type: sharedTypes.SET_BACKGROUND_PATTERN,
		payload: { backgroundPattern },
	};
}

export function toggleMobileFooterMenu(openMobileFooterMenu) {
	return {
		type: sharedTypes.TOGGLE_MOBILE_FOOTER_MENU,
		payload: { openMobileFooterMenu },
	};
}

export function handleUserExperiment(experimentName) {
	return {
		type: sharedTypes.HANDLE_USER_EXPERIMENT,
		payload: { experimentName },
	};
}

export function handleUserExperimentSuccess(experimentName) {
	return {
		type: sharedTypes.HANDLE_USER_EXPERIMENT_SUCCESS,
		payload: { experimentName },
	};
}

export function toggleGeneralFeedbackForm(openGeneralFeedbackForm) {
	return {
		type: sharedTypes.TOGGLE_GENERAL_FEEDBACK_FORM,
		payload: { openGeneralFeedbackForm },
	};
}
