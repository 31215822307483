import { processRequest } from '../../services/Api';
import { put, call, takeLatest, all } from 'redux-saga/effects';
import { stopSubmit } from 'redux-form';
import { getUtmTags, getUtmTagsLikeObject, removeUtmTags, setGTMData } from '../../services/Helper';
import { IS_MOBILE_APP } from '../../services/Constants';
import { historyPush, historyReplace } from '../../services/History';
import { signInActionTypes } from './Constants';
import { isSimpleEmail } from '../../services/FormValidate';
import * as signInActions from './Actions';
import * as pushNotificationActions from '../../components/appPushNotification/Actions';
import * as errorHandlerActions from '../../components/errorHandler/Actions';

export default function* () {
	yield all([yield takeLatest(signInActionTypes.LOGIN_REQUEST, handleLoginRequest)]);
	yield all([
		yield takeLatest(signInActionTypes.FIRST_VERIFY_REQUEST, handleFirstSocialVerifyRequest),
	]);
}

export function* handleLoginRequest(action) {
	try {
		const { email_or_username, password, path } = action.payload;

		const requestBody = { password };
		if (isSimpleEmail(email_or_username)) {
			requestBody.email = email_or_username;
		} else {
			requestBody.username = email_or_username;
		}
		const { data } = yield call(processRequest, 'auth/get_token', 'POST', requestBody);
		yield put(signInActions.loginSuccess(data.auth_token));

		yield call(historyReplace, path);

		if (
			(IS_MOBILE_APP && localStorage.deviceToken) ||
			localStorage.deviceToken === 'shouldRestore'
		) {
			yield put(
				pushNotificationActions.registerPushNotificationForDeviceRequest(
					localStorage.deviceToken,
				),
			);
		}
	} catch (e) {
		yield put(stopSubmit('signInForm', { password: e.response.data.info }));
		yield put(errorHandlerActions.handleError(e, 'notification'));
		yield put(signInActions.loginError());
	}
}

export function* handleFirstSocialVerifyRequest(action) {
	try {
		const { accessToken, tokenId, code, id_token, signedRequest, provider, user } =
			action.payload;
		const utmTagsString = getUtmTags();
		const utmTags = utmTagsString && getUtmTagsLikeObject(utmTagsString);
		const authentication = {
			social_authentication: {
				code,
				id_token,
				provider,
				access_token: accessToken,
				signed_request: signedRequest,
				token_id: tokenId,
			},
		};

		if (utmTags) {
			authentication.social_authentication.utm_tags = utmTags;
			removeUtmTags(utmTags);
		}

		const {
			data: { social_authentication },
		} = yield call(processRequest, 'public/social_authentications', 'POST', authentication);

		if (!social_authentication.newly_created_user) {
			yield put(signInActions.loginSuccess(social_authentication.auth_token));

			if (
				(IS_MOBILE_APP && localStorage.deviceToken) ||
				localStorage.deviceToken === 'shouldRestore'
			) {
				yield put(
					pushNotificationActions.registerPushNotificationForDeviceRequest(
						localStorage.deviceToken,
					),
				);
			}
			return yield call(historyReplace, '/');
		}

		setGTMData({ event: 'registrationStudent' });

		const initialValues = { ...social_authentication.user_account };
		if (user) {
			initialValues.first_name = user.name.firstName;
			initialValues.last_name = user.name.lastName;
		}
		yield put(signInActions.setSocialLoginState({ socialLoading: false }));
		yield put(
			signInActions.firstSocialVerifySuccess({
				token: social_authentication.auth_token,
				userId: social_authentication.user_account.user_id,
			}),
		);
		yield call(historyPush, '/sign-up');
	} catch (e) {
		yield put(errorHandlerActions.handleError(e, 'notification'));
		yield put(signInActions.firstSocialVerifyError());
	}
}
