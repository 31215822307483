import keyMirror from 'keymirror';

export const learningPathActionTypes = keyMirror({
	GET_LEARNING_PATH_REQUEST: null,
	GET_LEARNING_PATH_SUCCESS: null,
	GET_LEARNING_PATH_ERROR: null,

	SET_FINISH_TILE_ANIMATION: null,
	SET_SHORTER_LEARNING_PATH_ANIMATION: null,
	SET_SKIP_TILE_ANIMATION: null,

	UPDATE_ONBOARDING_DATA_REQUEST: null,

	UPDATE_LEARNING_PATH: null,
	UPDATE_TILE_PROGRESS_STATUS: null,
	SET_LEARNING_PATH_UPDATED: null,
	CLEAR_LEARNING_PATH: null,
	SET_REWARD_USER_SHEET_ID: null,
	SET_SELECTED_TILE: null,
	SET_CURRENT_STEPS: null,
	SET_NEW_STEPS: null,
	SET_TILES_TO_REPLACE: null,

	SET_PREVIEW_MODE: null,
	SET_TILE_SELECTION_MODE: null,

	SEND_COLLECTED_REWARD_REQUEST: null,
	SEND_COLLECTED_REWARD_SUCCESS: null,
	SEND_COLLECTED_REWARD_ERROR: null,

	RECEIVED_LEARNING_PATH_CHANNEL_STREAM: null,
	RECEIVED_LEARNING_PATH_CHANNEL_STREAM_SUCCESS: null,
});

export const TILE_STATUSES = {
	TILE_IN_PROGRESS: 'tile_in_progress',
	TILE_LOCKED: 'tile_locked',
	TILE_COMPLETED: 'tile_completed',
};

export const COLORS = {
	LAVENDER: 'lavender',
	TURQUOISE: 'turquoise',
	YELLOW: 'yellow',
	ORANGE: 'orange',
	GREY: 'grey',
	RAINBOW: 'rainbow',
};

export const COLORS_MAP = {
	[COLORS.LAVENDER]: 'rgba(126, 90, 247, 1)',
	[COLORS.TURQUOISE]: 'rgba(13, 207, 161, 1)',
	[COLORS.YELLOW]: 'rgba(255, 207, 0, 1)',
	[COLORS.ORANGE]: 'rgba(255, 151, 15, 1)',
	[COLORS.GREY]: 'rgba(211, 211, 211, 1)',
	[COLORS.RAINBOW]: 'rgba(255, 207, 0, 1)',
};

export const STATUS_TO_CLASS_MAP = {
	[TILE_STATUSES.TILE_IN_PROGRESS]: 'in-progress',
	[TILE_STATUSES.TILE_LOCKED]: 'locked',
	[TILE_STATUSES.TILE_COMPLETED]: 'completed',
};

export const TILES_PER_LINE = 9;

export const DEFAULT_TILE_IMAGE = '🕵️‍♀️';

export const TILE_HEIGHT = 102;

export const LEARNING_PATH_ONBOARDING_VIMEO_ID = '1047138368';

export const NEW_DIFFICULTY_ONBOARDING_VIMEO_ID = '1014284063';
