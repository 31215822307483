import { all, call, put, takeLatest, delay } from 'redux-saga/effects';
import i18n from 'i18next';
import { processRequest } from '../../services/Api';
import { getLocalStorageItem, setGTMData } from '../../services/Helper';
import { historyReplace } from '../../services/History';
import { isAuthenticated, setAccessToken } from '../../services/AccessToken';
import { DISCOUNT_CODE_KEY, shopActionTypes } from './Constants';
import { GA4_TAG_ID } from '../../services/Constants';
import * as errorHandlerActions from '../../components/errorHandler/Actions';
import * as shopActions from './Actions';
import * as sharedActions from '../../shared/Actions';
import * as notificationActions from '../../components/notification/Actions';

export default function* () {
	yield all([
		yield takeLatest(
			shopActionTypes.GET_PAYMENT_REQUESTS_SHOP_DATA,
			handlePaymentRequestsShopRequest,
		),
		yield takeLatest(shopActionTypes.GET_PAYMENT_METHODS_REQUEST, handlePaymentMethodsRequest),
		yield takeLatest(shopActionTypes.HANDLE_SUBSCRIPTION_REQUEST, handleSubscriptionRequest),
		yield takeLatest(shopActionTypes.GET_FAILED_PAYMENTS_REQUEST, handleFailedPaymentsRequest),
		yield takeLatest(shopActionTypes.GET_RECEIPT_DATA, handleGetReceiptDataRequest),
		yield takeLatest(shopActionTypes.CREATE_USER_SHOP_FORM, handleCreateUserShopForm),
		yield takeLatest(shopActionTypes.GET_SHOP_USER_DATA_REQUEST, handleShopUserDataRequest),
		yield takeLatest(shopActionTypes.VALIDATE_COUPON_REQUEST, handleValidateCouponRequest),
	]);
}

export function* handlePaymentRequestsShopRequest({ payload }) {
	try {
		const { packageId, paymentType } = payload || {};

		let requestPayload = {};
		if (packageId) {
			requestPayload = { package_id: packageId };
		}
		requestPayload.payment_method_type = paymentType;

		const { data } = yield call(processRequest, `payment_requests/1`, 'GET', requestPayload);

		yield put(
			shopActions.getPaymentRequestsShopSuccess(data.payment_request, data.discount_code),
		);
	} catch (e) {
		const { status, data } = e.response || {};
		if (status === 400 && data && data.hasOwnProperty('email')) {
			yield call(historyReplace, '/already-paid', data);
		}
		yield put(errorHandlerActions.handleError(e));
	}
}

export function* handlePaymentMethodsRequest() {
	try {
		const { data } = yield call(processRequest, 'adyen_services/payment_methods');

		yield put(shopActions.getPaymentMethodsSuccess(data));
	} catch (e) {
		yield put(errorHandlerActions.handleError(e));
	}
}

export function* handleSubscriptionRequest({ payload }) {
	const { paymentIntentId, shopData } = payload;

	function* handleStepsAfterSubscriptionCreation() {
		yield put(shopActions.handleSubscriptionSuccess());

		const { data } = yield call(processRequest, 'get_user_data');

		const { id } = data;

		yield put(sharedActions.getUserDataSuccess(data));

		if (shopData.chosen_plan_id) {
			yield call(processRequest, `users/${id}`, 'PUT', {
				user: { plan_id: shopData.chosen_plan_id },
			});
		}

		return yield call(historyReplace, '/bestellen-gelukt');
	}

	// prettier-ignore
	const purchaseEventData = ({id, amount, currency_code: currencyCode, plan}) => ({
		'event': 'purchase',
		'ecommerce': {
			'transaction_id': id.toString(),
			'value': parseInt(amount, 10) / 100,
			'tax': 0,
			'shipping': 0,
			'currency': currencyCode,
			'coupon': '',
			'items': [
				{
					'item_id': plan.identifier,
					'item_name': plan.membership_name,
					'price': parseInt(amount, 10) / 100,
					'quantity': 1,
				},
			],
		},
	});

	try {
		let isFulfilled = false;
		let paymentIntent;
		while (!isFulfilled) {
			yield delay(1000);
			const { data } = yield call(processRequest, `payment_intents/${paymentIntentId}`);
			paymentIntent = data.payment_intent;
			isFulfilled = paymentIntent.is_fulfilled;
			if (!isAuthenticated() && paymentIntent.jwt?.token) {
				setAccessToken(paymentIntent.jwt?.token);
				gtag &&
					gtag('config', GA4_TAG_ID, {
						client_id: paymentIntent.gtag?.client_id,
						session_id: paymentIntent.gtag?.session_id,
					});
			}
		}

		setGTMData({ ecommerce: null }); // Clear the previous ecommerce object.
		setGTMData(purchaseEventData(paymentIntent));

		yield call(processRequest, 'send_event', 'POST', {
			event_name: 'ga4_conversion',
			meta_data: purchaseEventData(paymentIntent),
		});

		yield call(handleStepsAfterSubscriptionCreation);
	} catch (e) {
		if (e.response.status === 422) {
			yield call(handleStepsAfterSubscriptionCreation);
		} else yield put(errorHandlerActions.handleError(e));
	}
}

export function* handleFailedPaymentsRequest({ payload }) {
	try {
		const { invoiceId, secret, paymentType } = payload;
		const requestPayload = { secret };

		if (paymentType) {
			requestPayload.payment_method_type = paymentType;
		}
		const { data } = yield call(
			processRequest,
			`public/invoices/${invoiceId}`,
			'GET',
			requestPayload,
		);

		yield put(shopActions.getFailedPaymentsSuccess(data.invoice));
	} catch (e) {
		if (e.response.status === 400) {
			yield call(historyReplace, '/already-fulfilled');
		} else yield put(errorHandlerActions.handleError(e));
	}
}

export function* handleGetReceiptDataRequest({ payload }) {
	try {
		const { planId } = payload;
		const discountCode = getLocalStorageItem(DISCOUNT_CODE_KEY);
		const requestPayload = {};

		if (discountCode) {
			requestPayload.discount_code = discountCode;
		}
		if (planId) {
			requestPayload.preferred_plan_id = planId;
		}
		const { data } = yield call(processRequest, '/public/packages/1', 'GET', requestPayload);

		yield put(shopActions.getReceiptDataSuccess(data));
	} catch (e) {
		yield put(errorHandlerActions.handleError(e));
	}
}

export function* handleCreateUserShopForm({ payload }) {
	const { planId, submitData, callback, setEmailExists, utmTags } = payload || {};
	const { email, first_name, last_name, coupon } = submitData || {};

	try {
		const body = {
			user_account: {
				email,
				first_name,
				last_name,
				plan_id: planId,
			},
			discount_code: coupon,
			utm_tags: utmTags,
		};

		const { data } = yield call(processRequest, 'public/user_accounts', 'POST', body);

		yield put(shopActions.createUserShopFormSuccess());
		yield put(sharedActions.getUserDataSuccess(data));

		setAccessToken(data.auth_token);

		callback && callback();
	} catch (e) {
		const error = e.response.data?.errors?.[0];
		if (error?.attribute === 'email' && error?.message === 'is al in gebruik') {
			yield call(setEmailExists, true);
		} else {
			yield put(errorHandlerActions.handleError(e, 'notification'));
		}
		yield put(shopActions.createUserShopFormError());
	}
}

export function* handleShopUserDataRequest() {
	try {
		const { data } = yield call(processRequest, 'get_user_data');

		const { has_paid_subscription } = data;

		yield put(shopActions.getShopUserSuccess(data));

		if (has_paid_subscription) {
			yield call(historyReplace, '/');
		}
	} catch (e) {
		yield put(shopActions.getShopUserError());
		yield put(errorHandlerActions.handleError(e, 'page-blocking'));
	}
}

export function* handleValidateCouponRequest({ payload }) {
	try {
		const { couponCode, planId, isFreeShop } = payload || {};

		const params = { coupon_code: couponCode, plan_id: planId };

		const { data } = yield call(processRequest, 'public/coupons/validate', 'GET', params);

		if (!isFreeShop && data.success && data.discount_percentage === 100) {
			yield call(historyReplace, `/bestellen/code?coupon=${couponCode}`);
		} else if (isFreeShop && data.success && data.discount_percentage < 100) {
			yield put(
				notificationActions.createNotification(
					i18n.t('shop.non_recurring_shop.error'),
					null,
					true,
					5000,
				),
			);
		} else {
			yield put(
				notificationActions.createNotification(data.message, null, !data.success, 5000),
			);
		}

		yield put(shopActions.validateCouponSuccess(data));
	} catch (e) {
		yield put(shopActions.validateCouponError());
		yield put(errorHandlerActions.handleError(e, 'ignore'));
	}
}
