import { learningPathActionTypes } from './Constants';

export function getLearningPathRequest() {
	return {
		type: learningPathActionTypes.GET_LEARNING_PATH_REQUEST,
	};
}

export function getLearningPathSuccess(data) {
	return {
		type: learningPathActionTypes.GET_LEARNING_PATH_SUCCESS,
		payload: {
			data,
		},
	};
}

export function getLearningPathError() {
	return {
		type: learningPathActionTypes.GET_LEARNING_PATH_ERROR,
	};
}

export function updateLearningPath(learningPath) {
	return {
		type: learningPathActionTypes.UPDATE_LEARNING_PATH,
		payload: {
			learningPath,
		},
	};
}

export function updateOnboardingDataRequest(key, status) {
	return {
		type: learningPathActionTypes.UPDATE_ONBOARDING_DATA_REQUEST,
		payload: {
			key,
			status,
		},
	};
}

export function updateTileProgressStatus(stepId, isCorrect) {
	return {
		type: learningPathActionTypes.UPDATE_TILE_PROGRESS_STATUS,
		payload: {
			stepId,
			isCorrect,
		},
	};
}

export function setLearningPathUpdated(isUpdated) {
	return {
		type: learningPathActionTypes.SET_LEARNING_PATH_UPDATED,
		payload: {
			isUpdated,
		},
	};
}

export function setFinishTileAnimation(finishTileAnimation) {
	return {
		type: learningPathActionTypes.SET_FINISH_TILE_ANIMATION,
		payload: {
			finishTileAnimation,
		},
	};
}

export function setTileSelectionMode(tileSelectionMode) {
	return {
		type: learningPathActionTypes.SET_TILE_SELECTION_MODE,
		payload: {
			tileSelectionMode,
		},
	};
}

export function setShorterLearningPathAnimation(shorterLearningPathAnimation) {
	return {
		type: learningPathActionTypes.SET_SHORTER_LEARNING_PATH_ANIMATION,
		payload: {
			shorterLearningPathAnimation,
		},
	};
}

export function setSkipTileAnimation(skipTileAnimation) {
	return {
		type: learningPathActionTypes.SET_SKIP_TILE_ANIMATION,
		payload: {
			skipTileAnimation,
		},
	};
}

export function setRewardUserSheetId(rewardUserSheetId) {
	return {
		type: learningPathActionTypes.SET_REWARD_USER_SHEET_ID,
		payload: {
			rewardUserSheetId,
		},
	};
}

export function setSelectedTile(selectedTile) {
	return {
		type: learningPathActionTypes.SET_SELECTED_TILE,
		payload: {
			selectedTile,
		},
	};
}

export function setCurrentSteps(steps) {
	return {
		type: learningPathActionTypes.SET_CURRENT_STEPS,
		payload: {
			steps,
		},
	};
}

export function setNewSteps(newSteps) {
	return {
		type: learningPathActionTypes.SET_NEW_STEPS,
		payload: {
			newSteps,
		},
	};
}

export function setTilesToReplace(tilesToReplace) {
	return {
		type: learningPathActionTypes.SET_TILES_TO_REPLACE,
		payload: {
			tilesToReplace,
		},
	};
}

export function sendCollectedRewardRequest(id, body, rewardType) {
	return {
		type: learningPathActionTypes.SEND_COLLECTED_REWARD_REQUEST,
		payload: {
			id,
			body,
			rewardType,
		},
	};
}

export function setPreviewMode(previewMode) {
	return {
		type: learningPathActionTypes.SET_PREVIEW_MODE,
		payload: {
			previewMode,
		},
	};
}

export function clearLearningPath() {
	return {
		type: learningPathActionTypes.CLEAR_LEARNING_PATH,
	};
}

export function receivedLearningPathChannelStream(data) {
	return {
		type: learningPathActionTypes.RECEIVED_LEARNING_PATH_CHANNEL_STREAM,
		payload: {
			data,
		},
	};
}

export function receivedLearningPathChannelStreamSuccess(data) {
	return {
		type: learningPathActionTypes.RECEIVED_LEARNING_PATH_CHANNEL_STREAM_SUCCESS,
		payload: {
			data,
		},
	};
}
