import notificationReducer from '../components/notification/Reducer';
import notificationsReducer from '../components/notifications/Reducer';
import userRoleModalReducer from './components/modals/userRoles/Reducer';
import usersOverviewReducer from './pages/users/Reducer';
import addWysiwygImageModalReducer from './components/wysiwyg/addImage/Reducer';
import addWysiwygVideoModalReducer from './components/wysiwyg/addVideo/Reducer';
import addWysiwygAnswerModalReducer from './components/wysiwyg/addAnswer/Reducer';
import addWysiwygFormulaModalReducer from './components/wysiwyg/addFormula/Reducer';
import adminPlaylistReducer from './pages/quiz/Reducer';
import adminInstructionReducer from './pages/instruction/Reducer';

import subjectsReducer from './pages/subjects/Reducer';
import subjectDetailsReducer from './pages/subjectDetails/Reducer';
import topicsReducer from './pages/topics/Reducer';

import manageSubjectReducer from './components/modals/manageSubject/Reducer';
import manageCategoryReducer from './components/modals/manageCategory/Reducer';
import manageSubcategoryReducer from './components/modals/manageSubcategory/Reducer';
import manageTopicReducer from './components/modals/manageTopic/Reducer';
import managePlaylistReducer from './components/modals/managePlaylist/Reducer';
import manageDifficultyReducer from './components/modals/manageDifficulty/Reducer';

const rootReducer = () => ({
	userRoleModalReducer,
	notificationReducer,
	notificationsReducer,
	usersOverviewReducer,
	addWysiwygImageModalReducer,
	addWysiwygVideoModalReducer,
	addWysiwygAnswerModalReducer,
	addWysiwygFormulaModalReducer,
	adminPlaylistReducer,
	adminInstructionReducer,

	subjectsReducer,
	subjectDetailsReducer,
	topicsReducer,

	manageSubjectReducer,
	manageCategoryReducer,
	manageSubcategoryReducer,
	manageTopicReducer,
	managePlaylistReducer,
	manageDifficultyReducer,
});

export default rootReducer;
