import { profileTypes } from './Constants';

export function setAvatarImage(publicLink) {
	return {
		type: profileTypes.SET_AVATAR_IMAGE,
		payload: {
			publicLink,
		},
	};
}

export function setAvatarSuccess() {
	return {
		type: profileTypes.SET_AVATAR_IMAGE_SUCCESS,
		payload: {},
	};
}

export function setAvatarError() {
	return {
		type: profileTypes.SET_AVATAR_IMAGE_ERROR,
		payload: {},
	};
}

export function checkPublicName(username) {
	return {
		type: profileTypes.CHECK_PUBLIC_NAME,
		payload: {
			username,
		},
	};
}

export function checkPublicNameRec() {
	return {
		type: profileTypes.CHECK_PUBLIC_NAME_REC,
		payload: {},
	};
}

export function checkPublicNameRes() {
	return {
		type: profileTypes.CHECK_PUBLIC_NAME_RES,
		payload: {},
	};
}

export function changePersonalDataRequest(first_name, last_name, child_name, grade_id) {
	return {
		type: profileTypes.SET_PERSONAL_DATA_REQUEST,
		payload: {
			first_name,
			last_name,
			child_name,
			grade_id,
		},
	};
}

export function changePersonalDataSuccess() {
	return {
		type: profileTypes.SET_PERSONAL_DATA_SUCCESS,
		payload: {},
	};
}

export function changePersonalDataError() {
	return {
		type: profileTypes.SET_PERSONAL_DATA_ERROR,
		payload: {},
	};
}

export function toggleChangeEmailModal(status) {
	return {
		type: profileTypes.TOGGLE_CHANGE_EMAIL_MODAL,
		payload: {
			status,
		},
	};
}

export function changeEmailRequest(email, password) {
	return {
		type: profileTypes.SET_NEW_EMAIL,
		payload: {
			email,
			password,
		},
	};
}

export function checkEmailRequest(email, admin) {
	return {
		type: profileTypes.CHECK_EMAIL,
		payload: {
			email,
			admin,
		},
	};
}

export function changeEmailSuccess(token) {
	return {
		type: profileTypes.SET_NEW_EMAIL_SUCCESS,
		payload: {
			token,
		},
	};
}

export function changeEmailError() {
	return {
		type: profileTypes.SET_NEW_EMAIL_ERROR,
		payload: {},
	};
}

export function toggleChangePasswordModal(status) {
	return {
		type: profileTypes.TOGGLE_CHANGE_PASSWORD_MODAL,
		payload: {
			status,
		},
	};
}

export function changePasswordRequest(newPassword, oldPassword) {
	return {
		type: profileTypes.SET_NEW_PASSWORD,
		payload: {
			newPassword,
			oldPassword,
		},
	};
}

export function changePasswordSuccess() {
	return {
		type: profileTypes.SET_NEW_PASSWORD_SUCCESS,
		payload: {},
	};
}

export function changePasswordError() {
	return {
		type: profileTypes.SET_NEW_PASSWORD_ERROR,
		payload: {},
	};
}

export function toggleDeleteModal(status) {
	return {
		type: profileTypes.TOGGLE_DELETE_MODAL,
		payload: {
			status,
		},
	};
}

export function deleteAccount(password) {
	return {
		type: profileTypes.DELETE_ACCOUNT_REQUEST,
		payload: {
			password,
		},
	};
}

export function deleteAccountSuccess() {
	return {
		type: profileTypes.DELETE_ACCOUNT_SUCCESS,
		payload: {},
	};
}

export function deleteAccountError() {
	return {
		type: profileTypes.DELETE_ACCOUNT_ERROR,
		payload: {},
	};
}

export function getNotificationPreferences(userId) {
	return {
		type: profileTypes.GET_NOTIFICATION_PREFERENCES_REQUEST,
		payload: {
			userId,
		},
	};
}

export function getNotificationPreferencesSuccess(preferences) {
	return {
		type: profileTypes.GET_NOTIFICATION_PREFERENCES_SUCCESS,
		payload: {
			preferences,
		},
	};
}

export function getNotificationPreferencesError(error) {
	return {
		type: profileTypes.GET_NOTIFICATION_PREFERENCES_ERROR,
		payload: {
			error,
		},
	};
}
export function updateNotificationPreferences(status, term, section) {
	return {
		type: profileTypes.UPDATE_NOTIFICATION_PREFERENCES_REQUEST,
		payload: {
			status,
			term,
			section,
		},
	};
}

export function updateNotificationPreferencesSuccess(preferences) {
	return {
		type: profileTypes.UPDATE_NOTIFICATION_PREFERENCES_SUCCESS,
		payload: {
			preferences,
		},
	};
}

export function updateNotificationPreferencesError(error) {
	return {
		type: profileTypes.UPDATE_NOTIFICATION_PREFERENCES_ERROR,
		payload: {
			error,
		},
	};
}

export function updateUserLocale(locale) {
	return {
		type: profileTypes.UPDATE_USER_LOCALE_REQUEST,
		payload: {
			locale,
		},
	};
}

export function getUserSubscription() {
	return {
		type: profileTypes.GET_USER_SUBSCRIPTION,
	};
}

export function getUserSubscriptionSuccess(subscriptions) {
	return {
		type: profileTypes.GET_USER_SUBSCRIPTION_SUCCESS,
		payload: {
			subscriptions,
		},
	};
}

export function getUserSubscriptionError(error) {
	return {
		type: profileTypes.GET_USER_SUBSCRIPTION_ERROR,
		error: true,
		payload: {
			error,
		},
	};
}

export function cancelSubscriptionRequest(date) {
	return {
		type: profileTypes.CANCEL_SUBSCRIPTION_REQUEST,
		payload: {
			date,
		},
	};
}

export function cancelSubscriptionSuccess() {
	return {
		type: profileTypes.CANCEL_SUBSCRIPTION_SUCCESS,
		payload: {},
	};
}

export function cancelSubscriptionError(error) {
	return {
		type: profileTypes.CANCEL_SUBSCRIPTION_REQUEST,
		error: true,
		payload: {
			error,
		},
	};
}

export function toggleCancelSubscriptionModal(status) {
	return {
		type: profileTypes.TOGGLE_CANCEL_SUBSCRIPTION_MODAL,
		payload: {
			status,
		},
	};
}
