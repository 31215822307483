/* eslint-disable import/no-cycle */
import addImageSaga from './components/modals/addImage/Sagas';
import appPushNotificationSagas from './components/appPushNotification/Sagas';
import chooseProfileImageModalSagas from './components/modals/chooseProfileImageModal/Sagas';
import couponReaderSagas from './pages/couponReader/Sagas';
import exerciseSagas from './pages/exercise/Sagas';
import forgotPasswordSagas from './pages/forgotPassword/Sagas';
import notificationsSagas from './components/notifications/Sagas';
import payInvoiceModalSagas from './components/modals/payInvoice/Sagas';
import paymentSagas from './pages/successPayment/Sagas';
import playButtonSagas from './components/playButton/Sagas';
import profileSagas from './pages/profile/Sagas';
import resetPasswordSagas from './pages/resetPassword/Sagas';
import shareVideoModalSagas from './components/modals/shareVideo/Sagas';
import sharedSagas from './shared/Sagas';
import shopSagas from './pages/shop/Sagas';
import signInSagas from './pages/signIn/Sagas';
import onboardingSaga from './components/onboarding/Sagas';
import reportSaga from './pages/report/Sagas';
import goalSettingsSaga from './pages/goalSettings/Sagas';
import learningPathSaga from './pages/learningPath/Sagas';
import dailyGoalSaga from './pages/dailyGoal/Sagas';
import stickersOnboardingSaga from './pages/stickersOnboarding/Sagas';
import dailyReportSaga from './components/dailyReport/Sagas';
import activitySaga from './pages/activity/Sagas';
import progressSaga from './pages/progress/Sagas';

const rootSaga = () => {
	return [
		addImageSaga,
		appPushNotificationSagas,
		chooseProfileImageModalSagas,
		couponReaderSagas,
		exerciseSagas,
		forgotPasswordSagas,
		notificationsSagas,
		payInvoiceModalSagas,
		paymentSagas,
		playButtonSagas,
		profileSagas,
		resetPasswordSagas,
		shareVideoModalSagas,
		sharedSagas,
		shopSagas,
		signInSagas,
		onboardingSaga,
		reportSaga,
		goalSettingsSaga,
		learningPathSaga,
		dailyGoalSaga,
		stickersOnboardingSaga,
		dailyReportSaga,
		activitySaga,
		progressSaga,
	];
};

export default rootSaga;
