import keyMirror from 'keymirror';

export const usersOverviewActionTypes = keyMirror({
	GET_USERS_REQUEST: null,
	GET_USERS_SUCCESS: null,
	GET_USERS_ERROR: null,
	CHANGE_USERS_LIST_FILTERS: null,

	GET_USER_INFO_REQUEST: null,
	GET_USER_INFO_SUCCESS: null,
	GET_USER_INFO_ERROR: null,

	CHANGE_USER_DETAILS_REQUEST: null,
	CHANGE_USER_DETAILS_SUCCESS: null,
	CHANGE_USER_DETAILS_ERROR: null,

	CHANGE_USER_SCHOOL_DETAILS_REQUEST: null,
	CHANGE_USER_SCHOOL_DETAILS_SUCCESS: null,
	CHANGE_USER_SCHOOL_DETAILS_ERROR: null,

	GET_USER_CHARGEBEE_INFO_REQUEST: null,
	GET_USER_CHARGEBEE_INFO_SUCCESS: null,
	GET_USER_CHARGEBEE_INFO_ERROR: null,

	USER_PASSWORD_RESET_REQUEST: null,
	USER_PASSWORD_RESET_SUCCESS: null,
	USER_PASSWORD_RESET_ERROR: null,

	ADMIN_DELETE_USER_REQUEST: null,

	GET_USER_HISTORY_REQUEST: null,
	GET_USER_HISTORY_SUCCESS: null,
	GET_USER_HISTORY_ERROR: null,

	CLEAR_USER_INFO: null,

	UPDATE_PERSONAL_DATA_REQUEST: null,
	UPDATE_PERSONAL_DATA_SUCCESS: null,
	UPDATE_PERSONAL_DATA_ERROR: null,
});

export const getMenuItems = (t) => [
	{ title: t('public_profile.tabs.details'), value: 'details', index: 0, link: 'details' },
	{ title: t('public_profile.tabs.history'), value: 'history', index: 3, link: 'history' },
];

export const getSortOptions = (t) => [
	{
		value: '1',
		title: t('admin_users.sort_options.by_date_desc'),
		options: {
			sort_field: 'created_at',
			sort_direction: 'desc',
		},
	},
	{
		value: '2',
		title: t('admin_users.sort_options.by_date_asc'),
		options: {
			sort_field: 'created_at',
			sort_direction: 'asc',
		},
	},
	{
		value: '3',
		title: t('admin_users.sort_options.by_name_desc'),
		options: {
			sort_field: 'last_name',
			sort_direction: 'asc',
		},
	},
	{
		value: '4',
		title: t('admin_users.sort_options.by_name_asc'),
		options: {
			sort_field: 'last_name',
			sort_direction: 'desc',
		},
	},
];

export const getSortOptionsExpanded = (t) => [
	{
		value: 'rel',
		title: t('search.sort_option'),
		options: {
			sort_field: 'rel',
			sort_direction: 'desc',
		},
	},
	...getSortOptions(t),
];

export const CHILD_FORM = 'ChildForm';
