import axios from 'axios';
import {
	COOKIES,
	ACCESS_TOKEN_EXPIRATION_DAYS,
	AUTH_URL,
	LOGOUT_REDIRECT_URL,
	IS_ADMIN,
} from './Constants';
import { isSecure, getCookie, setCookie, removeCookie } from './Helper';
import { processRequest } from './Api';

export const getAccessToken = () => {
	const token = getCookie(COOKIES.accessToken);
	const temporaryToken = getCookie(COOKIES.temporaryToken);

	return token || temporaryToken;
};

export const refreshAccessToken = async () => {
	try {
		if (IS_ADMIN) {
			const { data } = await processRequest('oauth2/token', 'POST');
			const { auth_token } = data || {};
			await setAccessToken(auth_token);
		} else {
			const { data } = await axios({
				headers: { 'Content-Type': 'application/json' },
				method: 'GET',
				params: { client_id: 'learning_path@squla', response_type: 'token' },
				crossDomain: true,
				mode: 'cors',
				url: AUTH_URL,
			});
			const { access_token, expires_in } = data || {};
			await setAccessToken(access_token, expires_in);
		}
	} catch (e) {
		removeAccessToken();
		window.location.href = LOGOUT_REDIRECT_URL;
	}
};

export const setAccessToken = (accessToken, expiresIn) => {
	const cookieOptions = {
		expires: ACCESS_TOKEN_EXPIRATION_DAYS,
		secure: isSecure(),
	};
	setCookie(COOKIES.accessToken, accessToken, cookieOptions);
	if (expiresIn) {
		const expiresAt = Math.floor(Date.now() / 1000) + expiresIn;
		setCookie(COOKIES.tokenValidUntil, expiresAt, cookieOptions);
	}
};

export const setTemporaryAccessToken = (token) => {
	setCookie(COOKIES.temporaryToken, token, {
		expires: ACCESS_TOKEN_EXPIRATION_DAYS,
		secure: isSecure(),
	});
};

export const removeAccessToken = () => {
	removeCookie(COOKIES.secureSession, { path: '/', secure: true });
	removeCookie(COOKIES.accessToken);
	removeCookie(COOKIES.tokenValidUntil);
};

export const removeTemporaryAccessToken = () => {
	removeCookie(COOKIES.temporaryToken);
};

export const isAuthenticated = () => {
	return !!getCookie(COOKIES.accessToken);
};

export const getTokenData = () => {
	try {
		const token = getCookie(COOKIES.accessToken);
		const tokenPayload = token.split('.')[1];
		const decodedTokenPayload = atob(tokenPayload);
		return JSON.parse(decodedTokenPayload);
	} catch (e) {
		if (e instanceof TypeError || e instanceof SyntaxError) {
			return null;
		}
		throw e;
	}
};

export const checkExpiredAccessToken = () => {
	const timestamp = getCookie(COOKIES.tokenValidUntil);
	const expiryDate = new Date(timestamp * 1000);
	const now = new Date();
	if (now > expiryDate) {
		return refreshAccessToken();
	}
	return Promise.resolve();
};
