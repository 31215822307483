import keyMirror from 'keymirror';

export const sidebarActionTypes = keyMirror({
	SET_EXPANDED_MENU: null,
	SET_SETTINGS_MENU: null,
});

export const MIN_DESKTOP_WIDTH = 1024;

export const sectionIcons = {
	users: 'icon-person',
	subjects: 'icon-grid',
	topics: 'icon-bookshelf',
};
