import {
	getLocalStorageItem,
	removeLocalStorageItem,
	setLocalStorageItem,
} from '../services/Helper';

/* left it for now, maybe logic will change
array should contain next object
{ name: 'ExperimentName', variantCount: 2, valid: true },
variantCount(2 - A/B test, 3 - A/B/C test, if valid is true FE will send experiment name in request headers)
export const experiments = []; */

export const getVariant = (list) => {
	const totalWeight = list.reduce((acc, item) => acc + item, 0);
	const random = Math.floor(Math.random() * totalWeight);
	let variant = 0;
	let cumulativeWeight = 0;

	for (let i = 0; i < list.length; i++) {
		cumulativeWeight += list[i];

		if (random <= cumulativeWeight) {
			variant = i;
			break;
		}
	}

	return variant;
};

export const setupExperiment = (experimentName, randomVariationWeights) => {
	const variant = getVariant(randomVariationWeights);

	setLocalStorageItem(`X-Experiment-${experimentName}`, variant);
};

export const getAllLocalStorage = () => {
	return Object.keys(localStorage).reduce((obj, k) => {
		obj.push({ key: k, value: localStorage.getItem(k) });
		return obj;
	}, []);
};

export const getRequestHeaderExperiments = () => {
	let headers = {};

	const allExperimentItems = getAllLocalStorage().filter((item) =>
		item.key.includes('X-Experiment'),
	);
	const experimentHeaders = allExperimentItems.reduce(
		(obj, item) => Object.assign(obj, { [item.key]: item.value }),
		{},
	);
	headers = { ...headers, ...experimentHeaders };

	/* left it for now, maybe logic will change
	const validExperiments = experiments.filter((item) => item.valid);
	const invalidExperiments = experiments.filter((item) => !item.valid);
	if (validExperiments.length) {
		const experimentHeaders = validExperiments.reduce((o, key) => {
			if (getLocalStorageItem(key.name))
				return Object.assign(o, { [key.name]: +getLocalStorageItem(key.name) });
			return {};
		}, {});
		headers = { ...headers, ...experimentHeaders };
	}
	if (invalidExperiments.length) {
		invalidExperiments.map((item) => {
			if (getLocalStorageItem(item.name)) removeLocalStorageItem(item.name);
			return item;
		});
	} */

	return headers;
};

export const handleExperiment = (experiment) => {
	const { name, random_variation_weights: randomVariationWeights } = experiment || {};

	if (name && !getLocalStorageItem(`X-Experiment-${name}`)) {
		setupExperiment(name, randomVariationWeights);
	}
};

export const deleteExperiment = (experimentName) => {
	if (experimentName && getLocalStorageItem(`X-Experiment-${experimentName}`)) {
		removeLocalStorageItem(`X-Experiment-${experimentName}`);
	}
};
